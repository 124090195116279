<button
  mat-icon-button
  [matMenuTriggerFor]="tableSettings"
  data-cy="table-settings-button"
>
  <mat-icon>settings</mat-icon>
</button>
<mat-menu #tableSettings="matMenu" (click)="$event.stopImmediatePropagation()">
  <div
    fxLayout="column"
    [fxLayoutGap]="0.5 | dsSpacing"
    [style.min-width]="10 | dsSpacing"
    class="full-height"
  >
    <h4 fxFlex="0 0 auto">{{ 'general.table_settings' | translate }}</h4>
    <div
      fxFlex="0 1 auto"
      class="settings-container full-width no-min-height scroll-container-vertical"
      cdkDropList
      (cdkDropListDropped)="
        moveItemInArray(
          settings,
          $event.previousIndex + startIndex,
          $event.currentIndex + startIndex
        )
      "
    >
      <div *ngFor="let settings of settings">
        @if (
          settings.disabledPosition === disabledPositionType.Start &&
          settings.name !== 'actions'
        ) {
          <div
            class="setting"
            mat-menu-item
            [disableRipple]="true"
            [fxLayoutGap]="0.5 | dsSpacing"
            fxLayoutAlign="space-between center"
            (click)="$event.stopImmediatePropagation()"
            [matTooltip]="
              'general.table_settings_fixed_column_tooltip' | translate
            "
          >
            <mat-checkbox
              class="sticky-checkbox"
              [(ngModel)]="settings.selected"
              [disabled]="true"
              data-cy="table-settings-disabled-items-checkbox"
              >{{ _intl.getColumnName(settings.name) }}</mat-checkbox
            >
            <mat-icon class="disabled">lock</mat-icon>
          </div>
        }

        @if (
          settings.disabledPosition === undefined ||
          settings.disabledPosition === disabledPositionType.Keep
        ) {
          <div
            class="setting"
            mat-menu-item
            cdkDrag
            cdkDragBoundary=".settings-container"
            fxLayoutAlign="space-between center"
            (click)="click($event, settings)"
            [fxLayoutGap]="0.5 | dsSpacing"
            cdkDragHandle
            [disableRipple]="!!settings.disabledPosition"
            [matTooltip]="
              'general.table_settings_keep_column_tooltip' | translate
            "
            [matTooltipDisabled]="!settings.disabledPosition"
          >
            <mat-checkbox
              [(ngModel)]="settings.selected"
              [disabled]="!!settings.disabledPosition"
              [ngClass]="!!settings.disabledPosition ? 'sticky-checkbox' : ''"
              (click)="$event.stopImmediatePropagation()"
              data-cy="table-settings-items-checkbox"
            >
              {{ _intl.getColumnName(settings.name) }}
            </mat-checkbox>
            <mat-icon>drag_handle</mat-icon>
          </div>
        }
      </div>
    </div>
    <button
      fxFlex="0 0 auto"
      class="save-button"
      mat-raised-button
      color="primary"
      (click)="onSave()"
      data-cy="table-settings-save-button"
    >
      {{ 'general.save' | translate }}
    </button>
  </div>
</mat-menu>
