import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileDropDirectiveModule } from '@cdk/file-drop-directive';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { StorePageSizeDirectiveModule } from '@cdk/store-page-size';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import {
  DsTableSettingsModule,
  TableSettingsIntl,
} from '@design-system/components/table-settings';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DeleteUndoModule } from '@ui-kit/form-control';
import { IsImagePipe } from '@utils/is-image-pipe';
import { LocaleDatePipeModule } from '@utils/locale-date-pipe';
import {
  ErrorCodeTreeComponent,
  PalcodeCheckComponent,
  SummaryInfoCardComponent,
} from './components';
import { ClaimHistoryLogComponent } from './components/claim-history-log/claim-history-log.component';
import { ContractTermsComponent } from './components/contract-terms/contract-terms.component';
import { CreateClaimComponent } from './components/create-claim/create-claim.component';
import { FaultCausingPartComponent } from './components/fault-causing-part/fault-causing-part.component';
import { SerialNumberComponent } from './components/serial-number/serial-number.component';
import { SparePartComponent } from './components/spare-part/spare-part.component';
import { SummaryFinancialCardComponent } from './components/summary-cards/summary-financial-card.component';
import { DecimalNumberDirective } from './directives/decimal-number.directive';
import {
  HighlightPipe,
  PrintAcceptedPipe,
  TranslateAcceptedPipe,
} from './pipes';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { TableSettingsI18n } from './components/claim-history-log/table-settings-i18n';

const sharedModules = [
  CommonModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatTableModule,
  MatTooltipModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSortModule,
  DsSpacingModule,
  DsSnackbarModule,
  DsTableModule,
  TranslateModule,
  PdLetDirectiveModule,
  DsFilterModule,
  StorePageSizeDirectiveModule,
  DsPlaceholderModule,
  DsTableLoadingModule,
  DsTableSettingsModule,
  DeleteUndoModule,
  FileDropDirectiveModule,
  MatExpansionModule,
  MatChipsModule,
  MatAutocompleteModule,
  LocaleDatePipeModule,
  IsImagePipe,
  DsFilterInputModule,
  MatDialogModule,
  MatListModule,
  MatSlideToggleModule,
  GoogleAnalytics4DirectiveModule,
];

@NgModule({
  declarations: [
    DecimalNumberDirective,
    SummaryInfoCardComponent,
    SummaryFinancialCardComponent,
    SerialNumberComponent,
    SparePartComponent,
    TranslateAcceptedPipe,
    ErrorCodeTreeComponent,
    FaultCausingPartComponent,
    PrintAcceptedPipe,
    HighlightPipe,
    ContractTermsComponent,
    CreateClaimComponent,
    PalcodeCheckComponent,
    ClaimHistoryLogComponent,
  ],
  imports: [sharedModules],
  exports: [
    ...sharedModules,
    DecimalNumberDirective,
    SummaryInfoCardComponent,
    SummaryFinancialCardComponent,
    SparePartComponent,
    TranslateAcceptedPipe,
    SerialNumberComponent,
    FaultCausingPartComponent,
    ErrorCodeTreeComponent,
    PrintAcceptedPipe,
    HighlightPipe,
    PalcodeCheckComponent,
    ClaimHistoryLogComponent,
    GoogleAnalytics4DirectiveModule,
  ],
  providers: [
    {
      provide: TableSettingsIntl,
      deps: [TranslateService],
      useFactory: (translateService) =>
        new TableSettingsI18n(translateService).getIntl(),
    },
  ],
})
export class SharedModule {}
