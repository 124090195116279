import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BASE_PATH } from '@claim-management-lib/data-access';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import { DsFeedbackFeatureModule } from '@design-system/feature/feedback';
import { DS_SPECIAL_PAGE_HOME_URL } from '@design-system/feature/special-pages';
import { AuthTokens, SharedFeatAuthModule } from '@features/auth';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  BASE_PATH as HELP_PAGES_BASE_PATH,
  HELP_PAGES_CONFIG,
  HelpPagesConfig,
} from '@paldesk/design-system/feature/help-page';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@shared-lib/data-access/identity-service-generated';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { CustomRouterStateSerializer } from './custom.router.state.serializer';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EQUIPMENT_PATH } from '@claim-management-lib/feat-claimdesk';
import {
  GoogleAnalytics4Service,
  GoogleAnalyticsTrackingId,
} from '@shared-lib/google-analytics';
import { WORKSHOP_GUIDE_PATH } from '@claim-management-lib/feat-claim-shared';

const devModules = environment.production
  ? []
  : [
      StoreDevtoolsModule.instrument({
        maxAge: 50,
        connectInZone: true,
      }),
    ];

@NgModule({
  imports: [
    StoreModule.forRoot(
      {
        router: routerReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
    }),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    SharedFeatAuthModule.forRoot(),
    ...devModules,
    DsFeedbackFeatureModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // APPLICATION LIST
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    {
      provide: DS_SPECIAL_PAGE_HOME_URL,
      useFactory: () => environment.LINKS.HOME,
    },
    // User Profile
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // analytics
    GoogleAnalytics4Service,
    {
      provide: GoogleAnalyticsTrackingId,
      useFactory: () => environment.google_analytics_id,
    },

    //help pages
    {
      provide: HELP_PAGES_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/ms-wiki',
    },
    {
      provide: HELP_PAGES_CONFIG,
      useFactory: () =>
        ({
          pagePath: '/Help pages/Claim Management',
          projectName: 'Palfinger.Paldesk',
          wikiName: 'Palfinger.Paldesk.wiki',
        }) as HelpPagesConfig,
    },
    {
      provide: BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/claim-management',
    },
    {
      provide: EQUIPMENT_PATH,
      useFactory: () => environment.LINKS.PALDESK_BASEPATH + '/equipment',
    },
    {
      provide: WORKSHOP_GUIDE_PATH,
      useFactory: () => environment.LINKS.WORKSHOP_GUIDE_URI,
    },
  ],
})
export class CoreModule {}
