import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@claim-management-lib/feat-claim-shared';
import { DashboardModule } from '@claim-management-lib/feat-dashboard';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DsLoadingModule } from '@design-system/components/loading';
import { DS_APP_WRAPPER_CONFIG } from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { DsPrintModule } from '@design-system/feature/print';
import {
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from '@features/hc-status-banner';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core.module';
import { AppGuard } from './app.guard';
import { AppWrapperModule } from '@design-system/feature/app-wrapper-v2';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    SharedLibFeatTranslationModule,
    DsPageModule,
    CoreModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          appInsightsRole: 'FE: CLAIM_MANAGEMENT',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appHelpPath: 'help',
          appName: 'Claim Management',
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    DsBreadcrumbsModule,
    DsLoadingModule,
    SharedModule,
    DsPrintModule,
    DashboardModule,
    GoogleAnalytics4Module.forRoot(
      {
        provide: GoogleAnalytics4MeasurementId,
        useFactory: () => environment.google_analytics_id,
      },
      false,
    ),
  ],
  bootstrap: [AppComponent],
  providers: [
    // HEALTHCHECK STATUS BANNER
    {
      provide: HEALTH_CHECK_OPTIONS_TOKEN,
      useFactory: (): HealthCheckBannerOptions => ({
        productsToCheck: ['Claim-Management'],
        statusPaldeskApiUri:
          environment.palfinger_api_management +
          '/status-paldesk/api/v1/health',
        statusPaldeskPageUri: environment.LINKS.STATUS_PALDESK_PAGE,
      }),
    },
    AppGuard,
  ],
})
export class AppModule {}
