/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import {
  ClaimService,
  ClaimService as ClaimServiceShared,
  ClaimStateCountStateName,
  ClaimdeskDecisionRow,
  ClaimdeskDecisionRowNavigationType,
  ClaimdeskRow,
  Company,
  DecisionClaimService,
  DeliveryService,
  GetClaimdeskSummary,
  GetUserContextResponse,
  ListDeskClaimsResponse,
  ClaimDeskService,
  ClaimdeskDecisionRowStateSpToGa,
} from '@claim-management-lib/data-access';
import {
  AccessService,
  ClaimListFacade,
  ClaimdDeskFilter,
  CreateClaimComponent,
  DisplayColumnsSettingsService,
  RecentClaimService,
  api_version,
} from '@claim-management-lib/feat-claim-shared';
import { PARTNERTYPES, ProductTypes } from '@config';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import { FilterValue } from '@design-system/components/filter-input';
import { TableSettings } from '@design-system/components/table-settings';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { UserService } from '@features/auth';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { filterTruthy } from '@shared-lib/rxjs';
import { TypedQueryParamsService } from '@shared-lib/typed-query-params';
import { saveAs } from 'file-saver';
import { Observable, Subject, of, throwError, filter } from 'rxjs';
import {
  catchError,
  debounceTime,
  finalize,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EQUIPMENT_PATH } from '../shared/tokens';
import { PromptComponent } from '@ui-kit/prompt';

@Component({
  selector: 'cm-claim-list',
  templateUrl: './claim-list.component.html',
  styleUrls: ['./claim-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimListComponent implements OnInit, OnDestroy {
  claimdeskDecisionRowNavigationType = ClaimdeskDecisionRowNavigationType;
  filterForm: FormGroup<{
    start_date: FormControl<Date | null>;
    end_date: FormControl<Date | null>;
    equipment_number: FormControl<string | null>;
    claim_number: FormControl<string | null>;
    delivery_note_number: FormControl<string | null>;
    service_partner: FormControl<number | null>;
    equipment_type: FormControl<string | null>;
    claim_type: FormControl<string[] | null>;
    internal_note: FormControl<string | null>;
  }>;
  dateType: 'NoDate' | 'Repair' | 'Create';
  availableClaimTypes: FilterValue[];
  partnerType: number;
  PartnerTypes = PARTNERTYPES;
  availableProductTypes: FilterValue[];
  servicePartners$: Observable<Company[]>;
  currency: string;
  currencySp: string;
  public summary$: Observable<GetClaimdeskSummary>;
  ClaimdeskDecisionRowStateSpToGa = ClaimdeskDecisionRowStateSpToGa;

  claimUserContext: GetUserContextResponse;

  dataSourceData?: MatTableDataSource<ClaimdeskRow | ClaimdeskDecisionRow>;
  exportRequestPending = false;
  customFilterMessage?: string;
  equipmentUrl: string;

  loading = false;

  paginator: MatPaginator;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if (mp && !this.paginator) {
      this.paginator = mp;
    }
  }
  sortDirection?: SortDirection;
  sortActive?: string;
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if (ms && this.dataSourceData) {
      this.dataSourceData.sort = ms;
    }
  }

  DsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;
  company_name: string;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public displayColumnsSettings: DisplayColumnsSettingsService,
    public claimServiceShared: ClaimServiceShared,
    public claimListDataFacade: ClaimListFacade,
    public claimListService: ClaimService,
    public recentClaimService: RecentClaimService,
    private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private snackbar: DsSnackbar,
    private readonly translateService: TranslateService,
    private dialog: MatDialog,
    private accessService: AccessService,
    private typedQueryParamsService: TypedQueryParamsService,
    private decisionService: DecisionClaimService,
    private deliveryService: DeliveryService,
    private claimDeskService: ClaimDeskService,
  ) {
    this.partnerType = this.userService.userContext.partnertype;
    this.company_name = this.userService.userContext.company_name;
    this.equipmentUrl = inject(EQUIPMENT_PATH);
  }

  ngOnInit() {
    this.route.params.subscribe(() => {
      this.handleFilter();
      this.initFilterForm();
    });

    if (this.partnerType === PARTNERTYPES.GENERAL_AGENT) {
      this.setDDLServicePartners();
    }

    this.accessService
      .getAccessStatus()
      .subscribe(
        (claimUserContext) => (this.claimUserContext = claimUserContext),
      );
    this.claimDeskService
      .getClaimdeskOptions(api_version)
      .subscribe((options) => {
        this.setDDLProductTypes();
        this.availableClaimTypes =
          options.claim_types?.map((x) => ({
            value: x,
            viewValue: this.translateService.instant(
              'claim-management.dashboard.' + x,
            ),
          })) || [];
      });
  }

  handleFilter() {
    switch (this.route.snapshot.queryParams.customFilter) {
      case 'ByStatusState':
        this.customFilterMessage = this.translateService.instant(
          'claim-management.dashboard.custom_filter.claims_filtered_ByStatus',
          {
            data: this.translateService.instant(
              'claim-management.dashboard.' +
                this.route.snapshot.queryParams.statusState,
            ),
          },
        );
        break;

      case 'ByProductLine':
        this.customFilterMessage = this.translateService.instant(
          'claim-management.dashboard.custom_filter.claims_filtered_ByProductLine',
          {
            data: this.translateService.instant(
              'general.product_types.' +
                this.route.snapshot.queryParams.productLineEquipmentType,
            ),
          },
        );
        break;

      case 'ByPeriod': {
        let data;
        const from = this.route.snapshot.queryParams.periodDayDiffFrom;
        const to = this.route.snapshot.queryParams.periodDayDiffTo;
        if (!from) {
          data = this.translateService.instant(
            'claim-management.dashboard.group.less_than',
            {
              to,
            },
          );
        } else if (!to) {
          data = this.translateService.instant(
            'claim-management.dashboard.group.more_than',
            {
              from,
            },
          );
        } else {
          data = this.translateService.instant(
            'claim-management.dashboard.group.range',
            {
              from,
              to,
            },
          );
        }
        this.customFilterMessage = this.translateService.instant(
          'claim-management.dashboard.custom_filter.claims_filtered_ByPeriod',
          {
            data,
          },
        );
        break;
      }

      case 'ByAcceptanceRatio':
        this.customFilterMessage = this.translateService.instant(
          'claim-management.dashboard.custom_filter.claims_filtered_ByAcceptanceRatio',
          {
            data: this.translateService.instant(
              'claim-management.decision.decline_handling_lump_sum.' +
                this.route.snapshot.queryParams.acceptanceRatioFilterType,
            ),
          },
        );
        break;
      default:
        this.customFilterMessage = undefined;
    }
  }

  getFilter(): ClaimdDeskFilter {
    let request: ClaimdDeskFilter = {
      startDate: DateUtils.toISODateStringLocal(
        this.filterForm.controls['start_date'].value
          ? new Date(this.filterForm.controls['start_date'].value)
          : undefined,
      ),
      endDate: DateUtils.toISODateStringLocal(
        this.filterForm.controls['end_date'].value
          ? new Date(this.filterForm.controls['end_date'].value)
          : undefined,
      ),
    };

    const queryParams = this.route.snapshot.queryParams;

    if (this.route.snapshot.params.id === 'custom') {
      ////// ClaimByStatusFilter
      if (queryParams.statusState) {
        request = {
          ...request,
          statusState: queryParams.statusState,
          statusForDecisionServicePartner:
            queryParams.statusForDecisionServicePartner,
        };
      }
      ////// ClaimsByProductLine
      else if (queryParams.productLineEquipmentType) {
        request = {
          ...request,
          productLineEquipmentType: queryParams.productLineEquipmentType,
        };
      }
      ////// ClaimsByPeriod
      else if (queryParams.periodDayDiffFrom) {
        request = {
          ...request,
          periodDayDiffFrom: queryParams.periodDayDiffFrom,
          periodDayDiffTo: queryParams.periodDayDiffTo,
          periodFilterType: queryParams.periodFilterType,
        };
      }
      ///// ClaimsByAcceptanceRatio
      else if (queryParams.acceptanceRatioFilterType) {
        request = {
          ...request,
          acceptanceRatioFilterType: queryParams.acceptanceRatioFilterType,
          acceptanceRatioTableType: queryParams.acceptanceRatioTableType,
          acceptanceRatioPartnerType: queryParams.acceptanceRatioPartnerType,
        };
      }
    } else {
      ////// Usual user filter
      request = {
        ...request,
        userFilterClaimStates: this.getClaimStateFromUrl(this.route.snapshot),
        userFilterClaimNumber:
          this.filterForm.controls['claim_number'].value?.trim(),
        userFilterEquipmentNumber:
          this.filterForm.controls['equipment_number'].value?.trim(),
        userFilterDeliveryNote:
          this.filterForm.controls['delivery_note_number'].value?.trim(),
        userFilterEquipmentType:
          this.filterForm.controls['equipment_type'].value || undefined,
        userFilterServicePartnerId:
          this.filterForm.controls['service_partner'].value &&
          this.filterForm.controls['service_partner'].value !== -1
            ? this.filterForm.controls['service_partner'].value
            : undefined,
        userFilterOnlyGaClaims:
          this.filterForm.controls['service_partner'].value === -1,
        userFilterInternalNote:
          this.filterForm.controls['internal_note'].value?.trim(),
        userFilterClaimTypes:
          this.filterForm.controls['claim_type'].value?.join(','),

        userFilterDateType: this.dateType,
      };
    }
    return request;
  }

  updateSummary() {
    this.summary$ = this.claimListDataFacade
      .getClaimDeskSummary(this.getFilter())
      .pipe(
        catchError((err) => {
          this.handleErrorResponse(err.error);
          return throwError(err.error);
        }),
      );
  }

  setDDLProductTypes() {
    this.availableProductTypes = Object.keys(ProductTypes)
      .sort((a, b) => (a < b ? -1 : 1))
      .map((key) => ({
        value: ProductTypes[key],
        viewValue: this.translateService.instant(
          'general.product_types.' + ProductTypes[key],
        ),
      }));
  }

  setDDLServicePartners() {
    this.servicePartners$ = this.claimServiceShared
      .getServicePartners(
        api_version,
        this.userService.userContext.bpm_company_id,
      )
      .pipe(
        map((partners) => partners.service_partners),
        catchError((err) => {
          this.handleErrorResponse(err.error);
          return of([]);
        }),
      );
  }

  initFilterForm() {
    this.dateType = this.typedQueryParamsService.get('date_type') || 'Create';
    const todaysDate = new Date();
    const beforeMonth = new Date();
    const isAllClaims =
      this.route.snapshot.params.id === 'all' &&
      !Object.keys(this.route.snapshot.queryParams).length;
    beforeMonth.setDate(beforeMonth.getDate() - 30);
    const start_date = this.typedQueryParamsService.get('start_date')
      ? new Date(this.typedQueryParamsService.get('start_date'))
      : isAllClaims
        ? beforeMonth
        : null;

    const end_date = this.typedQueryParamsService.get('end_date')
      ? new Date(this.typedQueryParamsService.get('end_date'))
      : isAllClaims
        ? todaysDate
        : null;

    this.filterForm = this.fb.group({
      start_date: new FormControl(start_date),
      end_date: new FormControl(end_date),
      equipment_number: new FormControl(
        this.typedQueryParamsService.get<string>('equipment_number'),
      ),
      claim_number: new FormControl(
        this.typedQueryParamsService.get<string>('claim_number'),
      ),
      delivery_note_number: new FormControl(
        this.typedQueryParamsService.get<string>('delivery_note_number'),
      ),
      service_partner: new FormControl(
        this.typedQueryParamsService.get<number>('service_partner'),
      ),
      equipment_type: new FormControl(
        this.typedQueryParamsService.get<string>('equipment_type'),
      ),
      claim_type: new FormControl(
        this.typedQueryParamsService.get<string[]>('claim_type'),
      ),
      internal_note: new FormControl(
        this.typedQueryParamsService.get<string>('internal_note'),
      ),
    });

    this.filterForm.valueChanges
      .pipe(
        startWith(this.filterForm.value),
        debounceTime(500),
        takeUntil(this.destroy$),
      )
      .subscribe({ next: () => this.filter() });
  }

  setUserSettings(userSettings: TableSettings[]): void {
    this.displayColumnsSettings.displayColumns = userSettings;
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  set dataSource(data: ListDeskClaimsResponse) {
    if (data.claimdesk_decision_rows)
      this.dataSourceData = new MatTableDataSource(
        data.claimdesk_decision_rows,
      ) as MatTableDataSource<ClaimdeskDecisionRow | ClaimdeskRow>;
    if (data.claimdesk_rows)
      this.dataSourceData = new MatTableDataSource(
        data.claimdesk_rows,
      ) as MatTableDataSource<ClaimdeskDecisionRow | ClaimdeskRow>;
    this.paginator.length = data.total_rows;
  }

  getClaimStateFromUrl(
    url: ActivatedRouteSnapshot,
  ): ClaimStateCountStateName | undefined {
    if (
      !url.params?.id ||
      url.params.id === 'all' ||
      url.params.id === 'custom'
    )
      return undefined;
    return url.params.id;
  }

  filter() {
    if (this.dataSourceData) {
      //recent claim data might be different from initial load
      this.recentClaimService.getStateFilter();
    }
    this.dataSourceData = undefined;
    this.paginator.pageIndex = 0;

    this.getData();

    this.updateSummary();
  }

  getCurrentSort(sort?: Sort | null): string {
    this.sortDirection = sort?.direction;
    this.sortActive = sort?.active;
    if (!sort?.direction) {
      return '';
    } else {
      return (
        this.getSortKey(sort.active) +
        ',' +
        sort.direction.charAt(0).toUpperCase() +
        sort.direction.slice(1)
      );
    }
  }

  getSortKey(key: string): string {
    const target_entry =
      this.displayColumnsSettings.palfingerColumnsEntries.find((entry) =>
        entry.includes(key),
      );
    return target_entry ? target_entry[0] : key;
  }

  getData() {
    this.loading = true;

    // update the URL params
    for (const [key, value] of Object.entries(this.filterForm.value)) {
      if (value !== null && value !== undefined) {
        this.typedQueryParamsService.set(key, value);
      } else {
        this.typedQueryParamsService.reset(key);
      }
    }
    this.typedQueryParamsService.set('date_type', this.dateType);

    //get the data
    this.claimListDataFacade
      .getClaimDeskData(
        this.getFilter(),
        this.paginator,
        this.getCurrentSort(this.dataSourceData?.sort),
      )
      .pipe(
        filterTruthy(),
        tap((data) => {
          if (data.display_options && !this.currency) {
            this.currency = data.display_options.currency;
            this.currencySp = data.display_options.currency_sp
              ? data.display_options.currency_sp
              : '';
          }
        }),
        switchMap((data) => {
          if (!this.displayColumnsSettings.displayColumns) {
            return this.displayColumnsSettings
              .setDisplayColumns()
              .pipe(map(() => data));
          } else return of(data);
        }),
        catchError((err) => {
          this.handleErrorResponse(err.error);
          return throwError(err.error);
        }),
        finalize(() => (this.loading = false)),
      )
      .subscribe((data) => (this.dataSource = data));
  }

  removeRow(claim: ClaimdeskRow): void {
    const dialogRef = this.dialog.open(PromptComponent);
    dialogRef.componentInstance.title = this.translateService.instant(
      'general.are_you_sure',
    );
    dialogRef.componentInstance.content = this.translateService.instant(
      'claim-management.dashboard.delete_confirmation',
      { claimNumber: claim.claim_number || claim.claim_id },
    );

    dialogRef
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        switchMap(() =>
          this.claimListService.deleteClaim(claim.claim_id, api_version).pipe(
            catchError((err) => {
              this.handleErrorResponse(err.error);
              return throwError(err.error);
            }),
          ),
        ),
      )
      .subscribe(() => {
        this.filter();
      });
  }

  getPrintReport(row: ClaimdeskRow | ClaimdeskDecisionRow) {
    let snackbarRef;
    this.snackbar
      .queue(
        this.translateService.instant('claim-management.preparing_document'),
        {
          type: DsSnackbarType.Info,
          duration: 1000000,
          action: this.translateService.instant('general.cancel'),
        },
      )
      .subscribe((ref) => (snackbarRef = ref));

    let serviceCall;

    switch (row.navigation_type) {
      case ClaimdeskDecisionRowNavigationType.Decision:
        serviceCall = this.decisionService.getDecisionClaimPrintReport(
          row.claim_id,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          api_version,
        );
        break;
      case ClaimdeskDecisionRowNavigationType.Edit:
        serviceCall = this.claimListService.getClaimPrintReport(
          row.claim_id,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          api_version,
        );
        break;
      case ClaimdeskDecisionRowNavigationType.Delivery:
      case ClaimdeskDecisionRowNavigationType.DecisionDelivery:
        serviceCall = this.deliveryService.getDeliveryClaimReport(
          row.claim_id,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          api_version,
        );
        break;
    }

    serviceCall
      .pipe(
        takeUntil(snackbarRef.onAction()),
        finalize(() => {
          snackbarRef.dismiss();
        }),
      )
      .subscribe({
        next: (result) => {
          saveAs(result, 'claim-' + row.claim_id);
        },
        error: (error) => {
          this.snackbar.queue(
            error.error?.message ||
              this.translateService.instant('claim-management.print_error'),
            {
              type: DsSnackbarType.Error,
            },
          );
          throw new Error(error);
        },
      });
  }

  getDetailRouterLink(row: ClaimdeskRow | ClaimdeskDecisionRow) {
    switch (row.navigation_type) {
      case ClaimdeskDecisionRowNavigationType.Decision:
        return ['/decision', row.claim_id];
      case ClaimdeskDecisionRowNavigationType.Edit:
        return ['/claim/edit', row.claim_id];
      case ClaimdeskDecisionRowNavigationType.Delivery:
        return ['/delivery', row.claim_id];
      case ClaimdeskDecisionRowNavigationType.DecisionDelivery:
        return ['/delivery/decision', row.claim_id];
    }
  }

  getEquipmentLink(equipment: string): string {
    const indexOfBracket = equipment.indexOf('(');

    if (indexOfBracket < 0) return this.equipmentUrl + '/' + equipment;

    return this.equipmentUrl + '/' + equipment.substring(0, indexOfBracket - 1);
  }

  downloadExportedData() {
    this.exportRequestPending = true;
    this.claimListDataFacade
      .getExportedClaims(
        this.getFilter(),
        this.getCurrentSort(this.dataSourceData?.sort),
      )
      .pipe(
        catchError((err) => {
          this.handleErrorResponse(err.error);
          return throwError(err.error);
        }),
        finalize(() => (this.exportRequestPending = false)),
      )
      .subscribe((file) => {
        const dateWritten = DateUtils.toISODateStringLocal(new Date());
        // We have to use saveAs function which converts the response to downloable file
        saveAs(file as any as Blob, 'Export_' + dateWritten + '.csv');
      });
  }

  createClaim() {
    this.dialog.open(CreateClaimComponent);
  }

  private handleErrorResponse(err: any) {
    this.snackbar
      .queue(
        err?.message ||
          this.translateService.instant('general.error_code.error'),
        {
          type: DsSnackbarType.Error,
          duration: 10 * 60000,
          action: err?.log_id
            ? this.translateService.instant('general.copy')
            : this.translateService.instant('general.dismiss'),
        },
      )
      .pipe(
        take(1),
        switchMap((ref) => ref.onAction()),
      )
      .subscribe(() => {
        navigator.clipboard.writeText(err?.log_id || '');
      });
  }
}
