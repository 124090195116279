import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';
import {
  DashboardService,
  PeriodGroupStatistics,
} from '@claim-management-lib/data-access';
import { api_version } from '@claim-management-lib/feat-claim-shared';
import { DsPresetCalenderHeaderComponent } from '@design-system/components/advanced-datepicker';
import { TranslateService } from '@ngx-translate/core';
import { DateUtils } from '@paldesk/shared-lib/utils/date-utils';
import { LegendPosition } from '@swimlane/ngx-charts';
import { Observable, map } from 'rxjs';

export enum PeriodMode {
  Return = 'Return',
  Create = 'Create',
}

interface GroupChartData {
  series: {
    name: string;
    value: number;
    extra?: any;
  }[];
}

interface ChartData {
  total_count: number;
  groups: GroupChartData[];
}

@Component({
  selector: 'cm-claims-by-period',
  templateUrl: './claims-by-period.component.html',
  styleUrls: ['./claims-by-period.component.scss'],
})
export class ClaimsByPeriodComponent implements OnInit {
  colorScheme = {
    domain: ['#41b563', '#758087', '#ff5864', '#3c88ec'],
  };
  view: [number, number] = [280, 300];
  legendPosition = LegendPosition.Below;

  claimsByPeriod$: Observable<ChartData>;

  dateRange: FormGroup<{
    from: FormControl<Date | null>;
    to: FormControl<Date | null>;
  }>;
  today = new Date();

  periodMode = PeriodMode;
  modeSelector: FormControl<PeriodMode> = new FormControl(PeriodMode.Create, {
    nonNullable: true,
  });

  DsPresetCalenderHeaderComponent = DsPresetCalenderHeaderComponent;

  repairSentDatePercentageGoal: number;
  repairSentDateCurrentPercentage: number;

  constructor(
    private fb: FormBuilder,
    private dashboardService: DashboardService,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.dateRange = this.fb.group({
      from: new FormControl(this.dateAdapter.addCalendarDays(this.today, -89)),
      to: new FormControl(this.today),
    });

    this.getData();
  }

  getData() {
    const from = this.dateRange.value.from;
    const to = this.dateRange.value.to;
    const serviceCall =
      this.modeSelector.value === PeriodMode.Return
        ? this.dashboardService.getReturnedSentGroups(
            api_version,
            from ? new Date(DateUtils.toISODateStringLocal(from)) : undefined,
            to ? new Date(DateUtils.toISODateStringLocal(to)) : undefined,
          )
        : this.dashboardService.getRepairedSentGroups(
            api_version,
            from ? new Date(DateUtils.toISODateStringLocal(from)) : undefined,
            to ? new Date(DateUtils.toISODateStringLocal(to)) : undefined,
          );
    this.claimsByPeriod$ = serviceCall.pipe(
      map((r) => this.setGroupChartData(r)),
    );
  }

  setGroupChartData(stats: PeriodGroupStatistics): ChartData {
    const res: GroupChartData[] = [
      {
        series:
          stats.claim_groups?.map((group) => {
            let name: string;
            if (!group.day_diff_from_inclusive) {
              this.repairSentDatePercentageGoal = group.goal || 0;
              this.repairSentDateCurrentPercentage =
                (group.claim_count * 100) / stats.total_count;
              name = this.translateService.instant(
                'claim-management.dashboard.group.less_than',
                {
                  to: group.day_diff_to_exclusive,
                },
              );
            } else if (!group.day_diff_to_exclusive) {
              name = this.translateService.instant(
                'claim-management.dashboard.group.more_than',
                {
                  from: group.day_diff_from_inclusive,
                },
              );
            } else {
              name = this.translateService.instant(
                'claim-management.dashboard.group.range',
                {
                  from: group.day_diff_from_inclusive,
                  to: group.day_diff_to_exclusive,
                },
              );
            }

            return {
              name,
              value: group.claim_count || 0,
              extra: group,
            };
          }) || [],
      },
    ];

    return {
      total_count: stats.total_count,
      groups: res[0].series.some((gr) => gr.value > 0) ? res : [],
    };
  }

  goToClaimsByPeriod(event) {
    if (!event.extra) {
      return;
    }
    this.router.navigate(['/claimdesk', 'custom'], {
      queryParams: {
        customFilter: 'ByPeriod',
        periodDayDiffFrom: event.extra.day_diff_from_inclusive,
        periodDayDiffTo: event.extra.day_diff_to_exclusive,
        periodFilterType:
          this.modeSelector.value === PeriodMode.Return
            ? 'ReturnedSentGroups'
            : 'RepairedSentGroups',
        start_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['from'].value || undefined,
        )})`,
        end_date: `date(${DateUtils.toISODateStringLocal(
          this.dateRange.controls['to'].value || undefined,
        )})`,
      },
    });
  }
}
