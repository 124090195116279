import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {
  ClaimDeskService,
  ClaimStateCountStateName,
  GetClaimdeskSummary,
  ListDeskClaimsResponse,
} from '@claim-management-lib/data-access';
import { Observable } from 'rxjs';
import { api_version } from '../constants';

export interface ClaimdDeskFilter {
  statusState?: ClaimStateCountStateName;
  statusForDecisionServicePartner?: boolean;
  productLineEquipmentType?: string;
  periodDayDiffFrom?: number;
  periodDayDiffTo?: number;
  periodFilterType?: 'ReturnedSentGroups' | 'RepairedSentGroups';
  acceptanceRatioFilterType?:
    | 'Accepted'
    | 'NotAccepted'
    | 'DeclinedUnknownReason'
    | 'DeclinedMissingProductionData'
    | 'DeclinedIncompleteBasicData'
    | 'DeclinedMissingDataFile';
  acceptanceRatioTableType?: 'Total' | 'HandlingLumpSum';
  acceptanceRatioPartnerType?: 'ServicePartner' | 'GeneralAgent';
  userFilterClaimNumber?: string;
  userFilterEquipmentNumber?: string;
  userFilterDeliveryNote?: string;
  userFilterClaimStates?: string;
  userFilterEquipmentType?: string;
  userFilterDateType?: 'NoDate' | 'Repair' | 'Create';
  userFilterServicePartnerId?: number;
  userFilterOnlyGaClaims?: boolean;
  userFilterClaimTypes?: string;
  userFilterInternalNote?: string;
  startDate?: string;
  endDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ClaimListFacade {
  constructor(public claimDeskService: ClaimDeskService) {}

  normalizeStringValue(controlValue: string | undefined) {
    return controlValue ? controlValue.trim() : '';
  }

  getClaimDeskData(
    claimDeskFilter: ClaimdDeskFilter,
    paginator: MatPaginator,
    sort: string,
  ): Observable<ListDeskClaimsResponse> {
    return this.claimDeskService.listClaimDeskClaims(
      api_version,
      paginator.pageIndex,
      paginator.pageSize,
      sort,
      claimDeskFilter.statusState,
      claimDeskFilter.statusForDecisionServicePartner,
      claimDeskFilter.productLineEquipmentType,
      claimDeskFilter.periodDayDiffFrom,
      claimDeskFilter.periodDayDiffTo,
      claimDeskFilter.periodFilterType,
      claimDeskFilter.acceptanceRatioFilterType,
      claimDeskFilter.acceptanceRatioTableType,
      claimDeskFilter.acceptanceRatioPartnerType,
      claimDeskFilter.userFilterClaimNumber,
      claimDeskFilter.userFilterEquipmentNumber,
      claimDeskFilter.userFilterDeliveryNote,
      claimDeskFilter.userFilterClaimStates,
      claimDeskFilter.userFilterEquipmentType,
      claimDeskFilter.userFilterDateType,
      claimDeskFilter.userFilterServicePartnerId,
      claimDeskFilter.userFilterOnlyGaClaims,
      claimDeskFilter.userFilterClaimTypes,
      claimDeskFilter.userFilterInternalNote,
      claimDeskFilter.startDate,
      claimDeskFilter.endDate,
    );
  }

  getClaimDeskSummary(
    claimDeskFilter: ClaimdDeskFilter,
  ): Observable<GetClaimdeskSummary> {
    return this.claimDeskService.getClaimDeskSummary(
      api_version,
      claimDeskFilter.statusState,
      claimDeskFilter.statusForDecisionServicePartner,
      claimDeskFilter.productLineEquipmentType,
      claimDeskFilter.periodDayDiffFrom,
      claimDeskFilter.periodDayDiffTo,
      claimDeskFilter.periodFilterType,
      claimDeskFilter.acceptanceRatioFilterType,
      claimDeskFilter.acceptanceRatioTableType,
      claimDeskFilter.acceptanceRatioPartnerType,
      claimDeskFilter.userFilterClaimNumber,
      claimDeskFilter.userFilterEquipmentNumber,
      claimDeskFilter.userFilterDeliveryNote,
      claimDeskFilter.userFilterClaimStates,
      claimDeskFilter.userFilterEquipmentType,
      claimDeskFilter.userFilterDateType,
      claimDeskFilter.userFilterServicePartnerId,
      claimDeskFilter.userFilterOnlyGaClaims,
      claimDeskFilter.userFilterClaimTypes,
      claimDeskFilter.userFilterInternalNote,
      claimDeskFilter.startDate,
      claimDeskFilter.endDate,
    );
  }

  getExportedClaims(
    claimDeskFilter: ClaimdDeskFilter,
    sort: string,
  ): Observable<Blob> {
    return this.claimDeskService.exportDeskClaims(
      api_version,
      claimDeskFilter.statusState,
      claimDeskFilter.statusForDecisionServicePartner,
      claimDeskFilter.productLineEquipmentType,
      claimDeskFilter.periodDayDiffFrom,
      claimDeskFilter.periodDayDiffTo,
      claimDeskFilter.periodFilterType,
      claimDeskFilter.acceptanceRatioFilterType,
      claimDeskFilter.acceptanceRatioTableType,
      claimDeskFilter.acceptanceRatioPartnerType,
      claimDeskFilter.userFilterClaimNumber,
      claimDeskFilter.userFilterEquipmentNumber,
      claimDeskFilter.userFilterDeliveryNote,
      claimDeskFilter.userFilterClaimStates,
      claimDeskFilter.userFilterEquipmentType,
      claimDeskFilter.userFilterDateType,
      claimDeskFilter.userFilterServicePartnerId,
      claimDeskFilter.userFilterOnlyGaClaims,
      claimDeskFilter.userFilterClaimTypes,
      claimDeskFilter.userFilterInternalNote,
      claimDeskFilter.startDate,
      claimDeskFilter.endDate,
      sort,
    );
  }
}
