/**
 * v1.1 - Claim Management API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1_claim_management
 * Contact: F.John@palfinger.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { FinancialStatistics } from '../model/financialStatistics';
// @ts-ignore
import { PeriodGroupStatistics } from '../model/periodGroupStatistics';
// @ts-ignore
import { PeriodStatistics } from '../model/periodStatistics';
// @ts-ignore
import { ProductLineStatistics } from '../model/productLineStatistics';
// @ts-ignore
import { StatusStatistics } from '../model/statusStatistics';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    protected basePath = 'https://api-int.test.paldesk.palfinger.com/claim-management';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param apiVersion The requested API version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFinancialStatistics(apiVersion: '1.1', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<FinancialStatistics>;
    public getFinancialStatistics(apiVersion: '1.1', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<FinancialStatistics>>;
    public getFinancialStatistics(apiVersion: '1.1', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<FinancialStatistics>>;
    public getFinancialStatistics(apiVersion: '1.1', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getFinancialStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<FinancialStatistics>(`${this.configuration.basePath}/dashboard/financial`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProductLineStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<ProductLineStatistics>;
    public getProductLineStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<ProductLineStatistics>>;
    public getProductLineStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<ProductLineStatistics>>;
    public getProductLineStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getProductLineStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'date_from');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'date_to');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<ProductLineStatistics>(`${this.configuration.basePath}/dashboard/product_lines`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRepairedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<PeriodGroupStatistics>;
    public getRepairedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<PeriodGroupStatistics>>;
    public getRepairedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<PeriodGroupStatistics>>;
    public getRepairedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getRepairedSentGroups.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'date_from');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'date_to');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<PeriodGroupStatistics>(`${this.configuration.basePath}/dashboard/repaired_sent_groups`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param dateFrom 
     * @param dateTo 
     * @param dayDiffThreshold 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRepairedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<PeriodStatistics>;
    public getRepairedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<PeriodStatistics>>;
    public getRepairedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<PeriodStatistics>>;
    public getRepairedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getRepairedSentStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'date_from');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'date_to');
        }
        if (dayDiffThreshold !== undefined && dayDiffThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dayDiffThreshold, 'day_diff_threshold');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<PeriodStatistics>(`${this.configuration.basePath}/dashboard/repaired_sent`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReturnedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<PeriodGroupStatistics>;
    public getReturnedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<PeriodGroupStatistics>>;
    public getReturnedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<PeriodGroupStatistics>>;
    public getReturnedSentGroups(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getReturnedSentGroups.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'date_from');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'date_to');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<PeriodGroupStatistics>(`${this.configuration.basePath}/dashboard/returned_sent_groups`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param dateFrom 
     * @param dateTo 
     * @param dayDiffThreshold 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReturnedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<PeriodStatistics>;
    public getReturnedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<PeriodStatistics>>;
    public getReturnedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<PeriodStatistics>>;
    public getReturnedSentStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, dayDiffThreshold?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getReturnedSentStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'date_from');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'date_to');
        }
        if (dayDiffThreshold !== undefined && dayDiffThreshold !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dayDiffThreshold, 'day_diff_threshold');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<PeriodStatistics>(`${this.configuration.basePath}/dashboard/returned_sent`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param apiVersion The requested API version
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatusStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<StatusStatistics>;
    public getStatusStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpResponse<StatusStatistics>>;
    public getStatusStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<HttpEvent<StatusStatistics>>;
    public getStatusStatistics(apiVersion: '1.1', dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json',}): Observable<any> {
        if (apiVersion === null || apiVersion === undefined) {
            throw new Error('Required parameter apiVersion was null or undefined when calling getStatusStatistics.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (dateFrom !== undefined && dateFrom !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateFrom, 'date_from');
        }
        if (dateTo !== undefined && dateTo !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>dateTo, 'date_to');
        }
        if (apiVersion !== undefined && apiVersion !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>apiVersion, 'api_version');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (identity_server) required
        localVarCredential = this.configuration.lookupCredential('identity_server');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/plain',
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }



        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        return this.httpClient.get<StatusStatistics>(`${this.configuration.basePath}/dashboard/statuses`,
            {
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
